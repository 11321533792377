<template>
  <div>
    <div class="card height-auto v-application--is-ltr text-left">
      <div class="card-body">
        <!-- <div class="row">
          <div class="col-md-4">
            <h3 class="main-color">{{ $t("Account Statement Details") }}</h3>
          </div>
        </div> -->

        <v-data-table
          :show-select="!is_parent"
          :loading="loading"
          loading-text="Loading... Please wait"
          v-model="selected"
          :headers="headers"
          :items="items"
          item-key="id"
          class="elevation-1 level1 templates"
          text="left"
          hide-default-footer
        >
          <template v-slot:item.data-table-select="{ item }">
            <v-checkbox
              v-model="selected"
              :value="item"
              :disabled="parseInt(item.remaining) <= 0"
              class="default-checkbox"
              color="#7297ff"
            ></v-checkbox>
          </template>
          <template v-slot:top>
            <v-row class="headerTable-div">
              <v-col
                md="4"
                sm="12"
                :class="
                  currentAppLocale == 'ar' ? 'text-right' : 'text-align-left'
                "
              >
                <h3 class="custom-header main-color">
                  {{ $t("Account Statement Details") }}
                </h3>
              </v-col>
              <v-col
                md="8"
                sm="12"
                :class="
                  currentAppLocale == 'ar' ? 'text-right' : 'text-align-left'
                "
              >
                <v-icon
                  class="main-color"
                  :class="
                    currentAppLocale == 'ar' ? 'float-right' : 'float-end'
                  "
                  @click="printAccountStatementDetailsPdf()"
                  :title="$t('Print All')"
                  >print</v-icon
                >
              </v-col>
            </v-row>
            <v-row class="">
              <v-col
                md="6"
                sm="12"
                :class="
                  currentAppLocale == 'ar' ? 'text-right' : 'text-align-left'
                "
              >
                <div class="bloc mb-2">
                  <span
                    :class="
                      currentAppLocale == 'ar'
                        ? 'ml-2 custom-bold'
                        : 'mr-2 custom-bold'
                    "
                    >{{ $t("Partner Name :") }}</span
                  >
                  <span class="custom-value">{{ partner.name }}</span>
                </div>
                <div class="block mb-2">
                  <span
                    :class="
                      currentAppLocale == 'ar'
                        ? 'ml-2 custom-bold'
                        : 'mr-2 custom-bold'
                    "
                    >{{ $t("Partner Code :") }}</span
                  >
                  <span class="custom-value">{{ partner.code }}</span>
                </div>
              </v-col>
              <v-col
                md="6"
                sm="12"
                :class="
                  currentAppLocale == 'ar' ? 'text-right' : 'text-align-left'
                "
              >
                <div class="block mb-2">
                  <span
                    :class="
                      currentAppLocale == 'ar'
                        ? 'ml-2 custom-bold'
                        : 'mr-2 custom-bold'
                    "
                    >{{ $t("Partner Type :") }}</span
                  >
                  <span class="custom-value">{{ partner.type }}</span>
                </div>
                <div class="block mb-2">
                  <span
                    :class="
                      currentAppLocale == 'ar'
                        ? 'ml-2 custom-bold'
                        : 'mr-2 custom-bold'
                    "
                    >{{ $t("Current Grade") }} :</span
                  >
                  <span class="custom-value">{{ partner.grade }}</span>
                </div>
              </v-col>
            </v-row>
            <hr class="basic" />
            <v-row class="">
              <v-col md="2" sm="12">
                <div
                  class="form-group"
                  :style="
                    currentAppLocale == 'ar'
                      ? 'text-align: right;'
                      : 'text-align: left;'
                  "
                >
                  <p class="cusom-bold">{{ $t("Academic Year") }}</p>
                </div>
              </v-col>
              <v-col md="4" sm="12">
                <div class="form-group">
                  <v-autocomplete
                    v-model="filter.year_id"
                    :items="years"
                    @change="hideLabel = true && getAllData()"
                    item-text="name"
                    item-value="id"
                    dense
                    outlined
                  ></v-autocomplete>
                </div>
              </v-col>
              <v-col md="3" sm="12" class="text-align-left">
                <div class="form-group">
                  <p>
                    <span
                      :class="
                        currentAppLocale == 'ar'
                          ? 'ml-2 custom-bold'
                          : 'mr-2 custom-bold'
                      "
                      >{{ $t("Related Grade:") }}</span
                    ><span class="custom-value">{{
                      partner.related_grade
                    }}</span>
                  </p>
                </div>
              </v-col>
              <v-col
                md="3"
                sm="12"
                :class="
                  currentAppLocale == 'en' ? 'text-right' : 'text-align-left'
                "
              >
                <v-btn
                  class="confirm success"
                  v-if="!is_parent"
                  :title="selected.length <= 0 ? 'Select Item(s) first' : ''"
                  :disabled="selected.length <= 0"
                  @click="createPayment()"
                >
                  {{ $t("Create Payment") }}
                </v-btn>
                <span
                  v-if="selected.length <= 0 && !is_parent"
                  class="custom-value"
                  :class="
                    currentAppLocale == 'en' ? 'text-right' : 'text-align-left'
                  "
                  style="font-size: 12px; display: block"
                  >{{ $t("Select Item(s) first") }}</span
                >
              </v-col>

              <!-- <v-col md="1" sm="12" class="text-right">
              </v-col> -->
            </v-row>
          </template>

          <template v-slot:item.item="{ item }">
            <p style="text-align: left">{{ item.item }}</p>
          </template>

          <template v-slot:item.status="{ item }">
            <p
              v-if="item.status_value == 0"
              :class="item.status_value == 0 ? 'text-green ' : ''"
            >
              {{ item.status }}
            </p>

            <p
              v-else-if="item.status_value == 2"
              :class="item.status_value == 2 ? 'text-green ' : ''"
            >
              {{ item.status }}
            </p>

            <p
              v-else-if="item.status_value == 1"
              :class="item.status_value == 1 ? ' ' : ' '"
            >
              {{ item.status }}
            </p>
          </template>

          <template v-slot:item.discounts="{ item }">
            <div v-if="item.allow_discount">
              <p
                class="main-color pointer"
                @click="openEditDial(item, 'view')"
                v-if="is_parent && item.discounts.length > 0"
              >
                {{ $t("View") }}
              </p>
              <p
                class="main-color pointer"
                @click="openEditDial(item, 'view')"
                v-if="
                  item.status_value == 1 &&
                  item.discounts.length > 0 &&
                  !is_parent
                "
              >
                {{ $t("View") }}
              </p>

              <p
                class="main-color pointer"
                @click="openEditDial(item, 'edit')"
                v-else-if="
                  item.status_value != 1 &&
                  item.discounts.length > 0 &&
                  !is_parent
                "
              >
                {{ $t("Edit") }}
              </p>
              <p
                class="main-color pointer"
                @click="openEditDial(item, 'edit')"
                v-else-if="
                  item.status_value != 1 &&
                  item.discounts.length == 0 &&
                  !is_parent
                "
              >
                {{ $t("Add") }}
              </p>
            </div>
            <div v-else-if="!item.allow_discount && !is_parent">
              <v-tooltip bottom max-width="1000px">
                <template v-slot:activator="{ on }">
                  <p dark v-on="on">N/A</p>
                </template>
                <span>
                  {{
                    $t(
                      "This item does not allow discount. You can change this item setting from Item Setup"
                    )
                  }}
                </span>
              </v-tooltip>
            </div>
          </template>

          <template v-slot:item.invoices="{ item }">
            <p
              class="main-color btn mb-2"
              style="display: block"
              v-for="(invo, index) in item.invoices"
              :key="index"
              @click="printInvoicePdf(invo)"
            >
              {{ invo }}
            </p>
          </template>

          <template slot="body.append">
            <tr class="hr">
              <td colspan="10"><hr class="second" /></td>
            </tr>
            <tr class="text-center">
              <td class="bold" colspan="4">{{ $t("Total") }}</td>
              <td class="bold">{{ totals.total_after_discount }}</td>
              <td class="bold">{{ totals.total_paid_amount }}</td>
              <td class="bold">{{ totals.total_remaining }}</td>
              <td class="bold"></td>
              <td class="bold"></td>
              <td class="bold"></td>
            </tr>
          </template>
        </v-data-table>

        <h2 v-if="!loading && items.length < 0" class="text-center">
          {{ $t("User not found") }}
        </h2>
      </div>
    </div>
    <!-- Dialog Discounts -->
    <v-dialog v-model="discountseditDialog" persistent max-width="600" class="">
      <v-form v-model="valid" ref="form">
        <v-card style="padding: 20px">
          <v-card-title class="headline"> {{ $t("Discounts") }} </v-card-title>

          <v-card-text>
            <!-- row Title -->
            <v-row>
              <v-col cols="12" md="5" class="item">
                <span class="bold">{{ $t("Item") }}</span> :
                {{ itemObject.item }}
              </v-col>
              <v-col cols="12" md="4" class="price">
                <span class="bold">{{ $t("Price") }}</span> :
                {{ itemObject.price }}
              </v-col>
              <v-col
                cols="12"
                md="3"
                :class="
                  currentAppLocale == 'en' ? 'text-right' : 'text-align-left'
                "
              >
                <v-btn v-if="mode == 'edit'" icon @click="addRowDiscount()">
                  <v-icon class="main-color" :title="$t('Add New')"
                    >add_circle</v-icon
                  >
                </v-btn>
              </v-col>
            </v-row>
            <hr />
            <!-- discount items -->
            <v-row>
              <v-col cols="12" md="4">
                <p class="bold">{{ $t("Discount") }}</p>
              </v-col>
              <v-col cols="12" md="3">
                <p class="bold" style="text-align: center">{{ $t("Value") }}</p>
              </v-col>
              <v-col cols="12" md="3">
                <p class="bold" style="text-align: center">
                  {{ $t("Percentage") }}
                </p>
              </v-col>
              <v-col cols="12" md="2"> </v-col>
            </v-row>
            <!-- items -->

            <v-row
              v-for="(discount, index) in itemObject.discounts"
              :key="index"
            >
              <v-col cols="12" md="4">
                <!-- :rules="[validationRules.required]" -->
                <v-autocomplete
                  v-if="mode == 'edit'"
                  :items="discounts"
                  v-model="discount.discount_id"
                  item-text="name"
                  item-value="id"
                  :rules="[validationRules.required]"
                  @change="setDicsountPercentage(discount)"
                  solo
                >
                </v-autocomplete>
                <p v-else>{{ discount.name }}</p>
              </v-col>
              <v-col cols="12" md="3">
                <p class="mt-2" style="text-align: center">
                  {{ discount_amount(discount) }}
                </p>
              </v-col>
              <v-col cols="12" md="3">
                <p class="mt-2" style="text-align: center">
                  {{ discount.discount_percentage }} %
                </p>
              </v-col>
              <v-col
                cols="12"
                md="2"
                :class="
                  currentAppLocale == 'en' ? 'text-right' : 'text-align-left'
                "
              >
                <v-btn
                  v-if="mode == 'edit'"
                  icon
                  @click="removeDiscount(index)"
                >
                  <v-icon class="main-color" :title="$t('Remove')"
                    >remove_circle</v-icon
                  >
                </v-btn>
              </v-col>
            </v-row>
            <hr />

            <!-- Totals -->
            <v-row v-if="total_discount_value > 0 && mode == 'edit'">
              <v-col cols="12" md="4">
                <p class="bold">{{ $t("Total Discount") }}</p>
              </v-col>
              <v-col cols="12" md="3" style="text-align: center">
                <p class="">{{ total_discount_value }}</p>
              </v-col>
              <v-col
                cols="12"
                md="2"
                :style="
                  currentAppLocale == 'en'
                    ? 'text-align: right'
                    : 'text-align: left'
                "
              >
                <p class="">{{ total_discount_percentage }} %</p>
              </v-col>
            </v-row>
            <v-row v-if="mode == 'view'">
              <v-col cols="12" md="4">
                <p class="bold">{{ $t("Total Discount") }}</p>
              </v-col>
              <v-col cols="12" md="3" style="text-align: center">
                <p class="">
                  {{ total_discount_value }}
                </p>
              </v-col>
              <v-col cols="12" md="2" style="text-align: right">
                <p class="">{{ total_discount_percentage }} %</p>
              </v-col>
              <!-- <v-col cols="12" md="3">
                
              </v-col> -->
            </v-row>
            <v-row v-if="total_discount_value > 0 && mode == 'view'">
              <v-col cols="12" md="4">
                <p class="bold">{{ $t("Price after discount") }}</p>
              </v-col>
              <v-col cols="12" md="3" style="text-align: center">
                <p class="">{{ price_after_discount }}</p>
              </v-col>
            </v-row>
            <v-row v-if="total_discount_value > 0 && mode == 'edit'">
              <v-col cols="12" md="4">
                <p class="bold">{{ $t("Price after discount") }}</p>
              </v-col>
              <v-col cols="12" md="3" style="text-align: center">
                <p class="">{{ price_after_discount }}</p>
              </v-col>
            </v-row>
            <v-row v-if="showValidation">
              <v-col cols="12" md="8">
                <p class="error--text">
                  {{ $t("total of discount percentage cannot exceed 100 %") }}
                </p>
              </v-col>
            </v-row>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn class="modal-btn-cancel cancelBtn" @click="closeEditDial">{{
              $t("Close")
            }}</v-btn>
            <v-btn
              v-if="!showValidation && mode == 'edit'"
              id=""
              @click="Confirm"
              :loading="loading"
              class="modal-btn-save"
              >{{ $t("Confirm") }}</v-btn
            >
            <!-- <v-btn
              v-if="mode == 'view'"
              @click="closeEditDial"
              class="modal-btn-save"
              >{{ $t("Ok") }}</v-btn
            > -->
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
    <snackComponent
      :snackbar="snackbar"
      :snackColor="snackColor"
      :snackMsg="snackMsg"
      @closeSnack="closeSnack"
    >
    </snackComponent>
  </div>
</template>

<script>
import axios from "axios";
import ACL from "../../acl";
import snackComponent from "@/components/snackComponent";
import { validationMixin } from "../../mixins/validationMixin";

export default {
  name: "AccountStatement",
  components: { snackComponent },
  mixins: [validationMixin],
  data() {
    return {
      // options: {
      //     itemsPerPage: 15,
      // },
      loading: false,
      // canConfirm: false,
      // showValidation: false,
      types: [],
      discountseditDialog: false,
      snackbar: false,
      snackColor: "",
      snackMsg: "",
      filterMenu: false,
      mode: "",
      formTitle: "",
      partner: {},
      selected: [],
      headers: [
        {
          text: this.$i18n.t("Item"),
          value: "item",
          sortable: false,
        },
        {
          text: this.$i18n.t("Due Date"),
          value: "due_date",
          sortable: false,
        },
        {
          text: this.$i18n.t("Price"),
          value: "price",
          sortable: false,
        },
        {
          text: this.$i18n.t("After Discount"),
          value: "after_discount",
          sortable: false,
        },
        {
          text: this.$i18n.t("Paid Amount"),
          value: "paid_amount",
          sortable: false,
        },
        {
          text: this.$i18n.t("Remaining"),
          value: "remaining",
          sortable: false,
        },
        {
          text: this.$i18n.t("Status"),
          value: "status",
          sortable: false,
        },
        {
          text: this.$i18n.t("Discount(s)"),
          value: "discounts",
          sortable: false,
        },
        {
          text: this.$i18n.t("Invoice Number"),
          value: "invoices",
          sortable: false,
        },
      ],
      valid: true,
      title: this.$i18n.t("Account Statement Details"),
      apiEndPoints: {
        list: "account-statement",
      },
      permissions: {
        list: ACL.checkPermission("list-account-statements"),
      },
      filter: {
        year_id: "",
      },
      years: [],
      items: [],
      search: "",
      code: "",
      itemObject: {},
      defaultItem: {},
      discounts: [],
      customItem: {
        after_discount: 300,
        due_date: "0000-00-00",
        id: 1,
        item: "Admission Exam",
        paid_amount: 200,
        price: 300,
        remaining: 100,
        status: "Partially Paid",
        status_value: 2,
        discounts: [],
      },
      is_parent: ACL.Role("Parent"),
      totals: [],
      // total_discount_value: 0,
      // total_discount_percentage: 0,
    };
  },
  watch: {},
  computed: {
    total_discount_value() {
      if (this.itemObject.discounts) {
        var total_discount_value = 0;
        this.itemObject.discounts.forEach((discount) => {
          total_discount_value += discount.discount_amount;
        });
        return total_discount_value;
      }
      return 0;
    },
    total_discount_percentage() {
      if (this.itemObject.discounts) {
        var total_discount_percentage = 0;
        this.itemObject.discounts.forEach((discount) => {
          total_discount_percentage += discount.discount_percentage;
        });
        return total_discount_percentage;
      }
      return 0;
    },
    price_after_discount() {
      if (this.itemObject.discounts) {
        return this.itemObject.price - this.total_discount_value;
      }
      return this.itemObject.price;
    },
    showValidation() {
      return parseInt(this.total_discount_percentage) > 100;
    },
    discount_amount() {
      return (discountRow) => {
        if (discountRow.discount_percentage) {
          var calc =
            (this.itemObject.price * discountRow.discount_percentage) / 100;
          discountRow.discount_amount = calc;
          return calc;
        } else {
          return 0;
        }
      };
    },
  },
  methods: {
    closeSnack() {
      this.snackbar = false;
    },
    getAllData() {
      axios
        .get(
          this.getApiUrl +
            "/account-statement/account-statement-details/" +
            this.code,
          {
            params: {
              filter: this.filter,
            },
            headers: {
              Authorization: "Bearer " + localStorage.token,
            },
          }
        )
        .then((response) => {
          // this.setPaginationParameters(response);
          // this.totalItems = response.data.data.total;
          this.items = [];
          this.items = response.data.data.items;
          this.partner = response.data.data.partner;
          this.totals = response.data.data.totals;
          this.loading = false;
          this.selected = [];
        });
    },

    createPayment() {
      if (this.selected.length == 0) {
        this.snackbar = true;
        this.snackColor = "red";
        this.snackMsg = this.$i18n.t("Please choose Item first");
        setTimeout(() => {
          this.snackbar = false;
        }, 3000);
      } else {
        var items_ids = [];
        this.selected.forEach((item) => {
          if (parseInt(item.remaining) > 0) items_ids.push(item.id);
        });
        this.$router.push(
          "/AccountStatements/" +
            this.code +
            "/createPayment?item_ids=" +
            items_ids +
            "&year_id=" +
            this.filter.year_id
        );
      }
    },
    openEditDial(item, mode) {
      this.discountseditDialog = true;
      this.$nextTick(() => {
        this.itemObject = Object.assign({}, item);
      });
      this.mode = mode;

      // console.log(item);
      // this.itemObject = this.customItem;
    },
    closeEditDial() {
      this.$nextTick(() => {
        this.itemObject = Object.assign({}, this.defaultItem);
      });
      // this.total_discount_value = 0;
      // this.total_discount_percentage = 0;
      this.canConfirm = false;
      this.discountseditDialog = false;
      // this.showValidation = false;
    },
    addRowDiscount() {
      const row = {
        discount_id: "",
        name: "",
        discount_amount: "",
        discount_percentage: "",
      };
      this.itemObject.discounts.push(row);
    },
    setDicsountPercentage(discount) {
      discount.discount_percentage = this.discounts.find(
        (item) => item.id == discount.discount_id
      ).percentage;
    },
    removeDiscount(index) {
      this.itemObject.discounts.splice(index, 1);
    },
    getDiscounts() {
      axios
        .get(this.getApiUrl + "/account-statement/get-all-discounts", {
          headers: {
            Authorization: "Bearer " + localStorage.token,
          },
        })
        .then((response) => {
          this.discounts = response.data.data;
        });
    },
    Confirm() {
      // sync-item-discounts/1
      // itemObject
      if (!this.valid) {
        this.$refs.form.validate();
      } else {
        // this.loading = true;
        const discounts = { discounts: this.itemObject.discounts };
        axios
          .post(
            this.getApiUrl +
              "/account-statement/sync-item-discounts/" +
              this.itemObject.account_statement_item_id,
            discounts,
            {
              headers: {
                Authorization: "Bearer " + localStorage.token,
              },
            }
          )
          .then((response) => {
            this.loading = false;
            if (response.data.status.error == true) {
              Object.assign(
                this.validation_errors,
                response.data.status.validation_errors
              );
            } else {
              this.closeEditDial();
              this.getAllData();
            }
          });
        console.log(this.itemObject);
      }
      // console.log("s");
    },
    printInvoicePdf(invo) {
      axios
        .get(this.getApiUrl + "/account-statement/export-invoice/" + invo, {
          headers: {
            Authorization: "Bearer " + localStorage.token,
          },
        })
        .then((response) => {
          if (response.data.status.error == false) {
            window.open(response.data.data);
            // this.close();
          }
        });
    },
    printAccountStatementDetailsPdf() {
      axios
        .get(
          this.getApiUrl +
            "/account-statement/export-account-statement-details/" +
            this.code,
          {
            params: {
              filter: this.filter,
            },
            headers: {
              Authorization: "Bearer " + localStorage.token,
            },
          }
        )
        .then((response) => {
          if (response.data.status.error == false) {
            window.open(response.data.data);
            // this.close();
          }
        });
    },
    getYears() {
      axios
        .get(this.getApiUrl + "/school/academic_periods_all", {
          headers: {
            Authorization: "Bearer " + localStorage.token,
            //the token is a variable which holds the token
          },
        })
        .then((response) => {
          this.years = response.data.data;
          this.years.forEach((item) => {
            if (item.is_active == 1) {
              this.filter.year_id = item.id;
            }
          });

          this.getAllData(this.page);

          // setTimeout(() => {
          //   this.getAllData(this.page);
          // }, 3000);
        });
    },
  },
  mounted() {
    this.code = this.$router.currentRoute.params.code;
    // this.getAllData(this.page);
    this.getDiscounts();
    this.getYears();
  },
};
</script>

<style lang="scss" scoped>
@import "../../styles/_variables.scss";
hr.second {
  border: 2px solid $main-color;
  margin: 10px auto;
  background-color: $main-color;
}
.item,
.price {
  padding-top: 12px !important;
}
tr.hr {
  background: transparent !important;
  padding: 0 !important;
  margin: 0 !important;
  border: 0 !important;
  box-shadow: transparent 0px 0px 0px 0px !important;
}
tr.hr td {
  border: 0;
  padding: 0;
  margin: 0;
}
</style>
